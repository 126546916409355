<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">
          {{ $t('elearning_tpm.training_schedule') }}
          <slot v-if="$route.query.id">
            {{ $t('globalTrans.update') }}
          </slot>
          <slot v-else>
            {{ $t('globalTrans.entry') }}
          </slot>
        </h4>
      </template>
      <template v-slot:searchHeaderAction>
        <router-link to="training-schedule" class="btn-add"><i class="ri-arrow-left-line"></i> {{ $t('elearning_tpm.training_schedule') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form id="form" @submit.prevent="handleSubmit(addAll)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="formData.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="6">
                <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                    </template>
                      <b-form-select
                      plain
                      v-model="formData.circular_memo_no"
                      :options="circularList"
                      id="circular_memo_no"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-overlay :show="load">
              <b-row>
                <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="formData.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Batch Id"  vid="batch_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="batch_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.batch_id"
                        :options="batchList"
                        id="batch_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Schedule Setup"  vid="training_schedule_setup_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_schedule_setup_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('elearning_config.schedule_setup') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_schedule_setup_id"
                        :options="trainingScheduleSetup"
                        id="training_schedule_setup_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" v-show="formData.training_schedule_setup_id === 5">
                  <ValidationProvider name="Title" vid="other_title">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="other_title"
                        slot-scope="{ valid, errors }"
                    >
                        <template v-slot:label>
                        {{$t('globalTrans.title')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                        id="other_title"
                        v-model="formData.other_title"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" v-show="formData.training_schedule_setup_id === 5">
                  <ValidationProvider name="Title Bn" vid="other_title_bn">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="other_title_bn"
                        slot-scope="{ valid, errors }"
                    >
                        <template v-slot:label>
                        {{$t('globalTrans.title_bn')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                        id="other_title_bn"
                        v-model="formData.other_title_bn"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Date" vid="training_date" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_date"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.select_date') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_date"
                        :options="selectDate"
                        id="training_date"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option value=''>{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Start Time" vid="start_time" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="start_time"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.start_time') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          type="time"
                          v-model="formData.start_time"
                          :placeholder="$t('globalTrans.select_date')"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="End Time" vid="end_time" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="end_time"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.end_time') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          type="time"
                          v-model="formData.end_time"
                          :placeholder="$t('globalTrans.select_date')"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" v-if="formData.training_schedule_setup_id === 3">
                  <ValidationProvider name="Lesson Module"  vid="course_module_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="course_module_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_tpm.course_module')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.course_module_id"
                        :options="courseModuleList"
                        id="course_module_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" v-if="formData.training_schedule_setup_id === 3">
                  <ValidationProvider name="Lesson"  vid="course_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="course_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_tpm.course_managment')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.course_id"
                        :options="courseList"
                        id="course_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" v-if="formData.training_schedule_setup_id !== 2">
                  <ValidationProvider name="Schedule Type"  vid="trainer_other" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="trainer_other"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('elearning_config.trainer_other') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.trainer_other"
                        :options="sheduleType"
                        id="trainer_other"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <slot v-if="formData.training_schedule_setup_id !== 2">
                  <b-col lg="6" sm="6" v-if="formData.trainer_other === 'Other'">
                    <ValidationProvider name="Other Name" vid="other_name" >
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="other_name"
                          slot-scope="{ valid, errors }"
                      >
                          <template v-slot:label>
                          {{$t('globalTrans.other_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                          id="other_name"
                          v-model="formData.other_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="6" v-if="formData.trainer_other === 'Other'">
                    <ValidationProvider name="Other Name Bn" vid="other_name_bn">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="other_name_bn"
                          slot-scope="{ valid, errors }"
                      >
                          <template v-slot:label>
                          {{$t('globalTrans.other_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                          id="other_name_bn"
                          v-model="formData.other_name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </slot>
                <b-col lg="6" sm="6" v-if="formData.trainer_other === 'Trainer' && formData.training_schedule_setup_id !== 2">
                  <ValidationProvider name="Trainer"  vid="trainer_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="trainer_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_tpm.trainer')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.trainer_id"
                        :options="trainerList"
                        id="trainer_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value=0>{{ trainerLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6" v-if="formData.training_schedule_setup_id === 2">
                  <ValidationProvider name="Guest Trainer"  vid="trainer_id">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="trainer_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_tpm.guest_trainer')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.trainer_id"
                        :options="guestTrainerListData"
                        id="trainer_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value=0>{{ trainerLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Vanue Name (En)" vid="venu_name">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="venu_name"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('elearning_config.vanue_name_en') }}
                    </template>
                    <b-form-input
                      id="venu_name"
                      v-model="formData.venue"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Vanue Name (Bn)" vid="venu_name_bn">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="venu_name_bn"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('elearning_config.vanue_name_bn') }}
                    </template>
                    <b-form-input
                      id="venu_name_bn"
                      v-model="formData.venue_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="12" sm="12" class="mb-2">
                  <b-button type="submit" variant="primary" class="btn btn-sm btn-primary float-right"><i class="fa fa-plus" aria-hidden="true"></i> {{$t('globalTrans.add_more')}}</b-button>
                </b-col>
                 <br/>
              </b-row>
              <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">{{$t('elearning_tpm.training_schedule')}} {{$t('globalTrans.list')}}</h4>
                </template>
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-12">
                      <b-table-simple hover small responsive bordered>
                        <b-tr style="background: #f3f3f3">
                          <b-th style="width:15%">{{$t('globalTrans.date')}}</b-th>
                          <b-th style="width:15%">{{$t('globalTrans.time')}}</b-th>
                          <b-th style="width:17%">{{$t('elearning_config.topics_session')}}</b-th>
                          <b-th style="width:17%">{{$t('elearning_config.speaker_facilitator')}}</b-th>
                          <b-th style="width:17%">{{ $t('elearning_config.vanue_name') }}</b-th>
                          <b-th style="width:5%">{{ $t('globalTrans.action') }}</b-th>
                        </b-tr>
                        <template v-if="scheduleList.length === 0">
                          <b-tr >
                            <b-th colspan="6" class="text-center text-danger" style="height: 70px;">
                              <br/>
                              {{$t('globalTrans.noDataFound')}}
                              <br/>
                            </b-th>
                          </b-tr>
                        </template>
                        <template v-else>
                          <b-tr v-for="(schedule,index) in scheduleList" :key="index">
                            <b-td>{{schedule.training_date | dateFormat}}</b-td>
                            <b-td>{{schedule.start_time | time12FormateTraining}} - {{schedule.end_time | time12FormateTraining}}</b-td>
                            <b-td>
                              {{ $i18n.locale === 'bn' ? schedule.topics_session_bn : schedule.topics_session }}
                            </b-td>
                            <b-td>
                              {{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}
                            </b-td>
                            <b-td>
                              {{ $i18n.locale === 'bn' ? schedule.venue_bn : schedule.venue }}
                            </b-td>
                            <b-td>
                              <!-- <b-button type="button" variant="info" style="padding-right: 2px;" class="btn btn-sm btn-info float-right" @click="editDetails(schedule)" ><i class="fas fa-edit"></i></b-button> -->
                              <b-button type="button" variant="danger" class="btn btn-sm btn-danger float-right" @click="scheduleList.splice(index, 1)"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </b-td>
                          </b-tr>
                        </template>
                      </b-table-simple>
                    </div>
                  </b-row>
                </template>
              </body-card>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <b-button type="button" variant="success" @click="createData" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <router-link to="training-schedule" class="mr-1 btn btn-danger btn-sm">{{ $t('globalTrans.cancel') }}</router-link>
                </div>
              </div>
            </b-overlay>
          </b-form>
        </ValidationObserver>
      </template>
    </card>
  </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingScheduleStore, trainingScheduleUpdate, trainingModuleWiseTrainer, trainingScheduleList, circularApproveList, getTrainer } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData = Object.assign({}, this.formData, {
      fiscal_year_id: this.$store.state.currentFiscalYearId
    })
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        sheduleId: 0,
        circular_memo_no: 0,
        fiscal_year_id: 0,
        batch_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        trainer_other: '',
        course_module_id: 0,
        course_id: 0,
        other_title_bn: '',
        other_title: '',
        other_name: '',
        other_name_bn: '',
        training_schedule_setup_id: 0,
        trainer_id: 0,
        training_date: '',
        start_time: '',
        end_time: ''
      },
      trainers: [],
      scheduleRemove: [],
      scheduleList: [],
      trainerLoading: false,
      officeTypeList: [],
      officeList: [],
      courseModuleList: [],
      courseList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerList: [],
      guestTrainerList: [],
      trainerListData: [],
      trainingTitleList: [],
      circularList: [],
      batchList: [],
      courseModuleError: '',
      trainerErrors: [],
      selectDate: []
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    guestTrainerListData: function () {
      return this.guestTrainerList.map(item => {
          return { value: item.value, text: this.currentLocale === 'en' ? item.text_en : item.text_bn }
        })
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    trainingScheduleSetup: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingScheduleSetup
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    sheduleType: function () {
      return this.$store.state.TrainingElearning.commonObj.sheduleType
    }
  },
  watch: {
    'formData.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'formData.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
      // this.getSheduleList()
    },
    'formData.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newValue) {
      this.courseModuleList = this.getcourseModuleList(newValue)
    },
    'formData.course_module_id': function (newValue) {
      this.courseList = this.getCourseList(newValue)
    },
    'formData.org_id': function (newValue) {
      this.batchList = this.getBatchList(newValue)
    },
    'formData.batch_id': function (newValue) {
      this.getTrainerName(newValue)
      this.getSheduleList()
    }
  },
  methods: {
    getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    async getTrainerName () {
      this.trainerLoading = true
      const params = {
        circular_memo_no: this.formData.circular_memo_no,
        batch_id: this.formData.batch_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, getTrainer, params)
      if (!result.success) {
        this.trainerList = []
        this.trainerLoading = false
      } else {
        this.trainerList = result.data.map(item => {
          return { value: item.value, text: this.currentLocale === 'en' ? item.text_en : item.text_bn, text_en: item.text_en, text_bn: item.text_bn }
        })
        this.guestTrainerList = result.guestTrainerList.map(item => {
          return { value: item.value, text: this.currentLocale === 'en' ? item.text_en : item.text_bn, text_en: item.text_en, text_bn: item.text_bn }
        })
        this.trainerLoading = false
      }
    },
    getcourseModuleList (trainingTitleId) {
      const courseModuleList = this.$store.state.TrainingElearning.commonObj.courseModuleList
      if (trainingTitleId) {
        return courseModuleList.filter(item => item.training_title_id === trainingTitleId)
      }
      return courseModuleList
    },
    editDetails (data) {
      this.formData.sheduleId = data.id
      this.formData.course_id = data.course_id
      this.formData.trainer_id = data.trainer_id
      this.formData.training_schedule_setup_id = data.training_schedule_setup_id
      this.formData.trainer_other = data.trainer_other
      this.formData.other_title = data.other_title
      this.formData.other_title_bn = data.other_title_bn
      this.formData.other_name = data.other_name
      this.formData.other_name_bn = data.other_name_bn
      this.formData.topics_session_bn = data.topics_session_bn
      this.formData.speaker = data.speaker
      this.formData.speaker_bn = data.speaker_bn
      this.formData.training_date = data.training_date
      this.formData.start_time = data.start_time
      this.formData.end_time = data.end_time
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularApproveList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
      this.circularLoading = false
    },
    removeShedule (index, data) {
      this.trainers.splice(index, 1)
      this.formData.scheduleRemove.push(data)
    },
    addAll () {
      let topicsSession = ''
      let topicsSessionBn = ''
      if (this.formData.training_schedule_setup_id === 5) {
        topicsSession = this.formData.other_title
        topicsSessionBn = this.formData.other_title_bn
      } else if (this.formData.training_schedule_setup_id === 3) {
        const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(this.formData.course_id))
        topicsSession = courseObj.text_en
        topicsSessionBn = courseObj.text_bn
      } else {
        const sheduleType = this.$store.state.TrainingElearning.commonObj.trainingScheduleSetup.find(sheduleType => sheduleType.value === parseInt(this.formData.training_schedule_setup_id))
        if (typeof sheduleType !== 'undefined') {
          topicsSession = sheduleType.text_en
          topicsSessionBn = sheduleType.text_bn
        }
      }
      let speaker = ''
      let speakerBn = ''
      if (this.formData.trainer_other === 'Other' && this.formData.other_name) {
        speaker = this.formData.other_name
        speakerBn = this.formData.other_name_bn
      } else {
        let trainerObj = ''
        if (this.formData.training_schedule_setup_id === 2) {
          trainerObj = this.guestTrainerList.find(doc => doc.value === parseInt(this.formData.trainer_id))
        } else {
          trainerObj = this.trainerList.find(doc => doc.value === parseInt(this.formData.trainer_id))
        }
        if (typeof trainerObj !== 'undefined') {
          speaker = trainerObj.text_en
          speakerBn = trainerObj.text_bn
        }
      }
      const checkData = {
        id: this.formData.sheduleId,
        circular_memo_no: this.formData.circular_memo_no,
        batch_id: this.formData.batch_id,
        fiscal_year_id: this.formData.fiscal_year_id,
        office_type_id: this.formData.office_type_id,
        office_id: this.formData.office_id,
        org_id: this.formData.org_id,
        training_category_id: this.formData.training_category_id,
        training_type_id: this.formData.training_type_id,
        training_title_id: this.formData.training_title_id,
        training_schedule_setup_id: this.formData.training_schedule_setup_id,
        other_title: this.formData.other_title,
        other_title_bn: this.formData.other_title_bn,
        other_name: this.formData.other_name,
        other_name_bn: this.formData.other_name_bn,
        course_id: this.formData.course_id,
        trainer_id: this.formData.trainer_id,
        trainer_other: this.formData.trainer_other,
        training_date: this.formData.training_date,
        start_time: this.formData.start_time,
        end_time: this.formData.end_time,
        venue: this.formData.venue,
        venue_bn: this.formData.venue_bn,
        topics_session: topicsSession,
        topics_session_bn: topicsSessionBn,
        speaker: speaker,
        speaker_bn: speakerBn
      }
      if (this.formData.sheduleId) {
        const datas = this.scheduleList.map((item, index) => {
          if (item.id === this.formData.sheduleId) {
            return Object.assign({}, item, checkData)
          } else {
            return Object.assign({}, item)
          }
        })
        this.scheduleList = datas
      } else {
        this.scheduleList.push(checkData)
      }
      this.sheduleReset()
    },
    sheduleReset () {
      this.formData.trainer_other = 'Other'
      this.formData.course_id = 0
      this.formData.other_title_bn = ''
      this.formData.other_title = ''
      this.formData.other_name = ''
      this.formData.other_name_bn = ''
      this.formData.training_schedule_setup_id = 0
      this.formData.trainer_id = 0
      // this.formData.training_date = null
      this.formData.start_time = ''
      this.formData.end_time = ''
      this.formData.sheduleId = 0
      this.formData.venue = ''
      this.formData.venue_bn = ''
      this.$refs.form.reset()
    },
    removeAttachment (index, data) {
      this.formData.module_attachments.splice(index, 1)
      if (data.pre_attachment) {
        this.formData.module_attachments_remove.push(data)
      }
    },
    getTrainingTitleList (typeId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (typeId) {
        return trainingTitleList.filter(item => item.training_type_id === typeId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    getCourseList (courseModuleId) {
      const courseList = this.$store.state.TrainingElearning.commonObj.courseList
      if (courseModuleId) {
        return courseList.filter(item => item.course_module_id === parseInt(courseModuleId))
      }
      return courseList
    },
    async getSheduleList () {
      this.loading = true
      const params = {
        no_pagination: true,
        circular_memo_no: this.formData.circular_memo_no,
        batch_id: this.formData.batch_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleList, params)
      if (!result.success) {
        this.scheduleList = []
        this.trainerListData = []
        this.loading = false
      } else {
        this.scheduleList = result.data
        this.trainerListData = result.trainerList
        this.loading = false
      }
    },
    async getCircularMemoNo (getCircularMemoNo) {
      if (getCircularMemoNo) {
        this.load = true
        const params = {
          circular_memo_no: getCircularMemoNo,
          table: 'iab_circular_publications'
        }
        this.trainerListData = []
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingModuleWiseTrainer, params)
        if (!result.success) {
          this.formData.fiscal_year_id = 0
          this.formData.org_id = 0
          this.formData.training_title_id = 0
          this.formData.training_type_id = 0
          this.courseList = []
        } else {
          this.lcErrorMsg = ''
          const trData = result.data
          this.formData.fiscal_year_id = trData.fiscal_year_id
          this.formData.org_id = trData.org_id
          this.formData.training_type_id = trData.training_type_id
          this.formData.training_title_id = trData.training_title_id
          const tmpList = result.aryRange.map((obj, index) => {
            return { value: obj, text: obj }
          })
          this.selectDate = tmpList
          // this.trainerListData = result.trainerList
        }
        this.load = false
      }
    },
    async createData () {
      this.load = true
      let result = null
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, this.scheduleList)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingScheduleStore, this.scheduleList)
      }
      this.load = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        // this.getSheduleList()
        this.$router.push('/training-e-learning-service/tpm/training-schedule')
      } else {
        this.trainerErrors = result.errors
        this.$refs.form.setErrors(result.errors)
        // this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
